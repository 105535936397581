@tailwind base;
@tailwind components;
@tailwind utilities;
// @import url('https://fonts.cdnfonts.com/css/helvetica-neue-9?styles=49034,49031,49033,49035,49032,49036,49038,49040,49042,49044,49037,49039,49041,49043,49045,49046');
// @import url('https://fonts.cdnfonts.com/css/coolvetica');

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'),
    url('assets/fonts/helvetica/HelveticaNeueLight.ttf') format('truetype');
}

@font-face {
  font-family: 'CanelaRegular';
  src: local('CanelaRegular'),
    url('assets/fonts/canela/Canela-Medium-Web.woff2') format('truetype');
}

html {
  scrollbar-gutter: stable;
}

body {
  max-width: 1440px;
  margin: 0 auto;
  font-family: 'HelveticaNeue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus {
  outline: 0px !important;
  outline-offset: 0px !important;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.step-1 {
  width: 50%;
  transition: width 1s ease-in-out;
}
.step-2 {
  width: 99%;
  transition: width 1s ease-in-out;
}
.step-3 {
  width: 99%;
  transition: width 1s ease-in-out;
}

.fade-in {
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 2) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-100 {
  animation-delay: 100ms;
}

.fade-out {
  -webkit-animation: fade-out 0.5s cubic-bezier(0.39, 0.575, 0.565, 2) both;
  animation: fade-out 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.rotate {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s ease-in-out;
}

.revert {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all 0.25s ease-in-out;
}

.gradient-underline {
  border-image: linear-gradient(to right, #ed6eca 0%, #f08e3b 100%) 30;
  border-width: 2px;
  border-style: solid;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.align-to-end {
  margin-right: -24px;
}

.pagination-squares {
  font-size: 14px;
  line-height: 150%;
  color: #333 !important;
  padding: 5px 9px;
}

.rc-pagination-next:focus,
.rc-pagination-next:hover .rc-pagination-item-link {
  border-color: #333 !important;
}

.rc-pagination-item-active a {
  color: #fff !important;
  background-color: #333 !important;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
  color: #333 !important;
  border-color: #333 !important;
}

.rc-pagination-item a:focus,
.rc-pagination-item a:hover {
  color: #333 !important;
}

.rc-pagination-item-active:focus,
.rc-pagination-item-active:hover {
  border-color: #333 !important;
}

.rc-pagination-item-active {
  border-color: #333 !important;
}

.rc-pagination-item-active a:focus,
.rc-pagination-item-active a:hover {
  color: #fff !important;
}

.rc-pagination-item {
  margin: -0.5px;
  border-radius: 0;
}

.rc-pagination-prev {
  margin: 0;
}

.rc-pagination-prev button:after {
  content: none !important;
}

.rc-pagination-next button:after {
  content: none !important;
}
.rc-pagination-prev button {
  background: url('assets/img/prev-icon.svg') no-repeat;
  background-position: calc(100% - 9px) calc(0.6em);
}

.rc-pagination-next button {
  background: url('assets/img/next-icon.svg') no-repeat;
  background-position: calc(100% - 7px) calc(0.6em);
}

.modal-overlay {
  position: fixed;
  overflow-x: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 100 !important;
  background-color: rgba(0, 0, 0, 0.4);
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #7748cc;
}

input[type='range']::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #7748cc;
}

.error {
  background-color: #fef2f2;
  border: thin solid #fca5a5;
}

.info {
  background-color: #f0f9ff;
  border: thin solid #bae6fd;
}

.success {
  background-color: #ecfdf5;
  border: thin solid #a7f3d0 !important;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #198754 !important;
}

.Toastify__close-button {
  margin-top: 16px;
}

.transparent-button {
  opacity: 0 !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.price-input {
  -moz-appearance: textfield;
}
.price-input::-webkit-inner-spin-button {
  display: none;
}
.price-input::-webkit-outer-spin-button,
.price-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.license-type-dropdown {
  width: 100% !important;
  height: 72px !important;
}

select#license-type-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white url('assets/img/license-type-arrow.svg') no-repeat;
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 32px;
  border: 1px solid #e7e9ed;
}

.license-duration-dropdown {
  width: 100% !important;
}

select#license-duration-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white url('assets/img/license-type-arrow.svg') no-repeat;
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 12px;
  border: 1px solid #e7e9ed;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 8px #1f2937;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
}

#creditCardNumber::-webkit-inner-spin-button {
  display: none;
}
#creditCardNumber::-webkit-outer-spin-button,
#creditCardNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#CVC::-webkit-inner-spin-button {
  display: none;
}
#CVC::-webkit-outer-spin-button,
#CVC::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#phoneNumber::-webkit-inner-spin-button {
  display: none;
}
#phoneNumber::-webkit-outer-spin-button,
#phoneNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#zipCode::-webkit-inner-spin-button {
  display: none;
}
#zipCode::-webkit-outer-spin-button,
#zipCode::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.link {
  color: #7748cc;
  text-decoration: underline;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.slide-top {
  opacity: 0;
  transform: translateY(100px);
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.animate-hover {
  div {
    margin-left: 4rem;
    transition: all 300ms ease-in-out;
  }
  @media only screen and (max-width: 600px) {
    div {
      margin-left: auto;
    }
  }
}
.animate-hover:hover {
  div {
    transition: all 300ms ease-in-out;
    margin-left: 5rem;
    margin-right: -6px;
    background: -webkit-linear-gradient(#ed6eca, #f08e3b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.fa-twitter {
  background-image: url('assets/img/icons/twitter-icon.svg');
  background-size: cover;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
.fa-tiktok {
  background-image: url('assets/img/icons/tiktok-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.fa-instagram {
  background-image: url('assets/img/icons/instagram-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.fa-youtube {
  background-image: url('assets/img/icons/youtube-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.fa-website {
  background-image: url('assets/img/icons/website-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.paralax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.calender-custom-selected:not([disabled]) {
  font-weight: bold;
  color: #7748cc;
  border: 2px solid currentColor;
}
.calender-custom-selected:hover:not([disabled]) {
  border-color: #7748cc;
  color: #7748cc;
}
.calender-custom-today {
  font-weight: bold;
  font-size: 140%;
  color: red;
}

.dashed-gradient-border {
  display: inline-block;
  border: 2px dotted #fff;
  background: linear-gradient(to bottom, #ffff, #f9f8fc 100%) padding-box,
    linear-gradient(to bottom, #ed6eca, #f08e3b 100%, #f08e3b) border-box;
}

.purple-gradient {
  background: var(
    --1234,
    linear-gradient(180deg, rgba(249, 248, 252, 0) 0%, #f9f8fc 100%)
  );
}

.shadow-bottom {
  position: relative;
}

.shadow-bottom::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px; /* Adjust this value to control the shadow's distance from the element */
  height: 8px; /* Adjust this value to control the height of the shadow */
  background: rgba(
    0,
    0,
    0,
    0.02
  ); /* Adjust the color and opacity of the shadow */
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.license-type-dropdown-small {
  width: 100% !important;
  height: 54px !important;
}

select#license-type-dropdown-small {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white url('assets/img/license-type-arrow.svg') no-repeat;
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 24px;
  border: 1px solid #e7e9ed;
}

@layer utilities {
  .border-gradient {
    transition: border-color 0.3s;
    border-image: linear-gradient(0deg, #f08e3b, #ed6eca) 1;
    border-image-slice: 1;
  }
}

.blue-gradient {
  mix-blend-mode: color-dodge;
}

.cover-all {
  z-index: 99900 !important;
}

@keyframes fadeInSide {
  from {
    transform: translateX(-250px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeInSidebar {
  from {
    transform: translateX(-90px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeOutSide {
  from {
    transform: translateX(220px);
  }
  to {
    transform: translateX(0);
  }
}

.fade-in-from-side {
  animation: fadeInSide 0.3s ease-in-out forwards;
}

.fade-in-element {
  animation: fadeInSidebar 0.2s ease-in-out forwards;
}

.fade-out-from-side {
  animation: fadeOutSide 0.3s ease-in-out forwards;
}
